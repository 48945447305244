import { Routes } from '@angular/router';
import { ImpressumComponent } from './pages/impressum/impressum.component';
import { IndexComponent } from './pages/index/index.component';
import { KfzSachverstaendigenSoftwareComponent } from './pages/kfz-sachverstaendigen-software/kfz-sachverstaendigen-software.component';
import { TeamComponent } from './pages/team/team.component';
import { JobsComponent } from './pages/jobs/jobs.component';
import { SeniorDeveloperComponent } from './pages/jobs/senior-developer/senior-developer.component';
import { OrganisationstalentComponent } from './pages/jobs/organisationstalent/organisationstalent.component';
import { CustomerSupportAgentComponent } from './pages/jobs/customer-support-agent/customer-support-agent.component';
import { JuniorDeveloperComponent } from './pages/jobs/junior-developer/junior-developer.component';
import { DeveloperComponent } from './pages/jobs/developer/developer.component';
import { KontaktComponent } from './pages/kontakt/kontakt.component';
import { DatenschutzComponent } from './pages/datenschutz/datenschutz.component';
import { NutzungsbedingungenComponent } from './pages/nutzungsbedingungen/nutzungsbedingungen.component';
import { GutachterWerdenComponent } from './pages/gutachter-werden/gutachter-werden.component';
import { TeilnahmebedingungenComponent } from './pages/teilnahmebedingungen/teilnahmebedingungen.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { PreiseComponent } from './pages/preise/preise.component';
import { AudatexPreisrechnerComponent } from './pages/preise/audatex-preisrechner/audatex-preisrechner.component';
import { DatPreisrechnerComponent } from './pages/preise/dat-preisrechner/dat-preisrechner.component';
import { GtmotivePreiseComponent } from './pages/preise/gtmotive-preise/gtmotive-preise.component';
import { RegistrierungComponent } from './pages/registrierung/registrierung.component';
import { PageWithHeaderAndFooterComponent } from './pages/page-with-header-and-footer/page-with-header-and-footer.component';
import { AudatexPartnerschaftComponent } from './pages/audatex-partnerschaft/audatex-partnerschaft.component';
import { ModalComponent } from './pages/preise/modal/modal.component';
import { ProduktWebinarComponent } from './pages/webinars/produkt-webinar/produkt-webinar.component';
import { AbtretungMackKohlComponent } from './pages/webinars/fachwebinare/abtretung-mack-kohl/abtretung-mack-kohl.component';
import { AufbauwebinarFahrzeugbewertungKohlComponent } from './pages/webinars/fachwebinare/aufbauwebinar-fahrzeugbewertung-kohl/aufbauwebinar-fahrzeugbewertung-kohl.component';
import { FallstrickeSchadenkalkulationKohlComponent } from './pages/webinars/fachwebinare/fallstricke-schadenkalkulation-kohl/fallstricke-schadenkalkulation-kohl.component';
import { GrundlagenFahrzeugbewertungKohlComponent } from './pages/webinars/fachwebinare/grundlagen-fahrzeugbewertung-kohl/grundlagen-fahrzeugbewertung-kohl.component';
import { GutachtentexteKohlComponent } from './pages/webinars/fachwebinare/gutachtentexte-kohl/gutachtentexte-kohl.component';
import { Kasko1KohlComponent } from './pages/webinars/fachwebinare/kasko-1-kohl/kasko-1-kohl.component';
import { Kasko2KohlComponent } from './pages/webinars/fachwebinare/kasko-2-kohl/kasko-2-kohl.component';
import { MinderwertKohlComponent } from './pages/webinars/fachwebinare/minderwert-kohl/minderwert-kohl.component';
import { TextbausteineImGutachtenKohlComponent } from './pages/webinars/fachwebinare/nachtexte-kohl/textbausteine-im-gutachten-kohl.component';
import { OldtimerDeuschleComponent } from './pages/webinars/fachwebinare/oldtimer-deuschle/oldtimer-deuschle.component';
import { RestwertKohlComponent } from './pages/webinars/fachwebinare/restwert-kohl/restwert-kohl.component';
import { StellungnahmenKohlComponent } from './pages/webinars/fachwebinare/stellungnahmen-kohl/stellungnahmen-kohl.component';
import { ZustandsberichteKohlComponent } from './pages/webinars/fachwebinare/zustandsberichte-kohl/zustandsberichte-kohl.component';
import { ZweiradgutachtenKohlComponent } from './pages/webinars/fachwebinare/zweiradgutachten-kohl/zweiradgutachten-kohl.component';
import { WiederbeschaffungswertKohlComponent } from './pages/webinars/fachwebinare/wiederbeschaffungswert-kohl/wiederbeschaffungswert-kohl.component';
import { AudatexKalkulationKohlComponent } from './pages/webinars/fachwebinare/audatex-kalkulation-kohl/audatex-kalkulation-kohl.component';
import { HaftungsfaelleHaftungsfalleKohlComponent } from './pages/webinars/fachwebinare/haftungsfaelle-haftungsfalle-kohl/haftungsfaelle-haftungsfalle-kohl.component';
import { DatKalkulationKohlComponent } from './pages/webinars/fachwebinare/dat-kalkulation-kohl/dat-kalkulation-kohl.component';
import { BuchhaltungSchlieferComponent } from './pages/webinars/fachwebinare/buchhaltung-schliefer/buchhaltung-schliefer.component';
import { TechnicalItSupport } from './pages/jobs/technical-support-agent/support-and-product-manager.component';
import { GesamtkostenrechnerComponent } from './pages/preise/gesamtkostenrechner/gesamtkostenrechner.component';
import { NeuerungenInAutoixpertSchlieferComponent } from './pages/webinars/fachwebinare/neuerungen-in-autoixpert-schliefer/neuerungen-in-autoixpert-schliefer.component';
import { PowerUserDokumenteSchlieferComponent } from './pages/webinars/fachwebinare/power-user-dokumente-schliefer/power-user-dokumente-schliefer.component';
import { FuerWechslerComponent } from './pages/fuer-wechsler/fuer-wechsler.component';
import {
    HonorarkuerzungenBurkardComponent
} from "./pages/webinars/fachwebinare/honorarkuerzungen-burkard/honorarkuerzungen-burkard.component";

/**
 * Don't split these routes into separate files unless you adjust the logic in get-canonical-url.ts.
 */
export const routes: Routes = [
    // Pages without Header + Footer
    {
        path: 'Registrierung',
        component: RegistrierungComponent,
    },
    // Pages with Header + Footer
    {
        path: '',
        component: PageWithHeaderAndFooterComponent,
        children: [
            {
                path: '',
                component: IndexComponent,
            },
            {
                path: 'impressum',
                component: ImpressumComponent,
            },
            {
                path: 'kfz-sachverstaendigen-software',
                component: KfzSachverstaendigenSoftwareComponent,
            },
            {
                path: 'preise',
                component: PreiseComponent,
            },
            {
                path: 'fuer-wechsler',
                component: FuerWechslerComponent,
            },
            {
                path: 'gesamtkostenrechner',
                component: GesamtkostenrechnerComponent,
            },
            {
                path: 'audatex-preisrechner',
                component: AudatexPreisrechnerComponent,
            },
            {
                path: 'dat-preisrechner',
                component: DatPreisrechnerComponent,
            },
            {
                path: 'gtmotive-preise',
                component: GtmotivePreiseComponent,
            },
            {
                path: 'modal',
                component: ModalComponent,
            },
            {
                path: 'team',
                component: TeamComponent,
            },
            {
                path: 'webinar',
                component: ProduktWebinarComponent,
            },
            {
                path: 'fachwebinare/abtretung-mack-Kohl',
                component: AbtretungMackKohlComponent,
            },
            {
                path: 'fachwebinare/audatex-kalkulation-Kohl',
                component: AudatexKalkulationKohlComponent,
            },
            {
                path: 'fachwebinare/aufbauwebinar-fahrzeugbewertung-Kohl',
                component: AufbauwebinarFahrzeugbewertungKohlComponent,
            },
            {
                path: 'fachwebinare/buchhaltung-schliefer',
                component: BuchhaltungSchlieferComponent,
            },
            // {
            //     path: 'fachwebinare/Dokumentengestaltung-Schliefer',
            //     component: DokumentengestaltungSchlieferComponent,
            // },
            // {
            //     path: 'fachwebinare/Erfahrungsaustausch-fuer-Sachverstaendige-Schliefer',
            //     component: ErfahrungsaustauschSchlieferComponent,
            // },
            {
                path: 'fachwebinare/fallstricke-schadenkalkulation-Kohl',
                component: FallstrickeSchadenkalkulationKohlComponent,
            },
            {
                path: 'fachwebinare/grundlagen-fahrzeugbewertung-Kohl',
                component: GrundlagenFahrzeugbewertungKohlComponent,
            },
            {
                path: 'fachwebinare/gutachtentexte-Kohl',
                component: GutachtentexteKohlComponent,
            },
            {
                path: 'fachwebinare/faftungsfälle-haftungsfalle-Kohl',
                component: HaftungsfaelleHaftungsfalleKohlComponent,
            },
            {
                path: 'fachwebinare/honorarkuerzungen-burkard',
                component: HonorarkuerzungenBurkardComponent
            },
            {
                path: 'fachwebinare/kalkulation-dat-Kohl',
                component: DatKalkulationKohlComponent,
            },
            {
                path: 'fachwebinare/kasko-1-Kohl',
                component: Kasko1KohlComponent,
            },
            {
                path: 'fachwebinare/kasko-2-Kohl',
                component: Kasko2KohlComponent,
            },
            {
                path: 'fachwebinare/gutachtentexte-Kohl',
                component: GutachtentexteKohlComponent,
            },
            {
                path: 'fachwebinare/minderwert-Kohl',
                component: MinderwertKohlComponent,
            },
            {
                path: 'fachwebinare/textbausteine-im-gutachten-kohl',
                component: TextbausteineImGutachtenKohlComponent,
            },
            {
                path: 'fachwebinare/neuerungen-in-autoixpert-schliefer',
                component: NeuerungenInAutoixpertSchlieferComponent,
            },
            {
                path: 'fachwebinare/power-user-dokumente-schliefer',
                component: PowerUserDokumenteSchlieferComponent,
            },
            {
                path: 'fachwebinare/oldtimer-deuschle',
                component: OldtimerDeuschleComponent,
            },
            {
                path: 'fachwebinare/restwert-Kohl',
                component: RestwertKohlComponent,
            },
            {
                path: 'fachwebinare/stellungnahmen-Kohl',
                component: StellungnahmenKohlComponent,
            },
            {
                path: 'fachwebinare/zustandsberichte-Kohl',
                component: ZustandsberichteKohlComponent,
            },
            {
                path: 'fachwebinare/zweiradgutachten-Kohl',
                component: ZweiradgutachtenKohlComponent,
            },
            {
                path: 'fachwebinare/wiederbeschaffungswert-Kohl',
                component: WiederbeschaffungswertKohlComponent,
            },
            {
                path: 'jobs',
                component: JobsComponent,
            },
            {
                path: 'jobs/senior-developer',
                component: SeniorDeveloperComponent,
            },
            {
                path: 'jobs/developer',
                component: DeveloperComponent,
            },
            {
                path: 'jobs/junior-developer',
                component: JuniorDeveloperComponent,
            },
            {
                path: 'jobs/organisationstalent',
                component: OrganisationstalentComponent,
            },
            {
                path: 'jobs/customer-support-agent',
                component: CustomerSupportAgentComponent,
            },
            {
                // @deprecated - remove after Dec 1, 2024
                path: 'jobs/produkt-und-support-manager',
                component: TechnicalItSupport,
            },
            {
                path: 'jobs/technischer-it-support',
                component: TechnicalItSupport,
            },
            {
                path: 'kontakt',
                component: KontaktComponent,
            },
            {
                path: 'datenschutz',
                component: DatenschutzComponent,
            },
            {
                path: 'nutzungsbedingungen',
                component: NutzungsbedingungenComponent,
            },
            {
                path: 'gutachter-werden',
                component: GutachterWerdenComponent,
            },
            {
                path: 'audatex-partnerschaft',
                component: AudatexPartnerschaftComponent,
            },
            {
                path: 'teilnahmebedingungen',
                component: TeilnahmebedingungenComponent,
            },
            {
                path: '404',
                component: NotFoundComponent,
            },
            /**
             * 404 page
             */
            { path: '**', pathMatch: 'full', component: NotFoundComponent },
        ],
    },
];
