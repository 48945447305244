import type { Event as SentryAngularEvent, EventHint as SentryAngularEventHint } from '@sentry/angular';
import {
    AxError
} from './ax-error';

export function getAxErrorEventProcessorForSentry() {
    /**
     * Ensure AxError.code is used as the error name in Sentry instead of "ServerError".
     */
    return function (event: SentryAngularEvent, hint: SentryAngularEventHint) {
        if (event.exception) {
            let axError: AxError;
            if ((hint.originalException as AxError)?.type === 'AxError') {
                axError = hint.originalException as AxError;
            }
            if (axError) {
                for (const sentryException of event.exception.values) {
                    /**
                     * Without our modification, the Sentry error event's title would simply be "ServerError". However,
                     * that's too generic. Use the AxError code instead.
                     */
                    if (axError.code === 'INTERNAL_ERROR') {
                        /**
                         * Internal errors are the most common errors. We want to know the exact error message in Sentry to differentiate between
                         * different internal errors.
                         */
                        sentryException.type = axError.message;
                    } else {
                        sentryException.type = axError.code;
                    }

                    //*****************************************************************************
                    //  AxError Data
                    //****************************************************************************/
                    /**
                     * Context data can be viewed in Sentry's issue details screen. This is very useful for debugging.
                     */
                    if (axError.data) {
                        let stringifiedAxErrorData: string;
                        try {
                            // Pretty-print JSON with an indent of 4 spaces. This makes reading the causedBy chain easy for developers.
                            stringifiedAxErrorData = JSON.stringify(axError.data, null, 4);
                        } catch (error) {
                            stringifiedAxErrorData = `The AxError.data could not be JSON.stringify()ed: ${error.toString()}`;
                        }

                        if (!Array.isArray(hint.attachments)) {
                            hint.attachments = [];
                        }
                        hint.attachments.push({
                            filename: 'AxError-data.json',
                            data: stringifiedAxErrorData,
                        });
                    }
                    /////////////////////////////////////////////////////////////////////////////*/
                    //  END AxError Data
                    /////////////////////////////////////////////////////////////////////////////*/

                    //*****************************************************************************
                    //  AxError CausedBy Chain
                    //****************************************************************************/
                    if (axError.causedBy) {
                        let stringifiedCausedByChain: string;
                        try {
                            // Pretty-print JSON with an indent of 4 spaces. This makes reading the causedBy chain easy for developers.
                            stringifiedCausedByChain = JSON.stringify(axError.causedBy, null, 4);
                        } catch (error) {
                            stringifiedCausedByChain = `The causedBy-chain could not be JSON.stringify()ed: ${error.toString()}`;
                        }

                        if (!Array.isArray(hint.attachments)) {
                            hint.attachments = [];
                        }
                        hint.attachments.push({
                            filename: 'AxError-causedBy.json',
                            data: stringifiedCausedByChain,
                        });
                    }
                    /////////////////////////////////////////////////////////////////////////////*/
                    //  END AxError CausedBy Chain
                    /////////////////////////////////////////////////////////////////////////////*/
                }
            }
        }

        // returning `null` will drop the event
        return event;
    };
}
