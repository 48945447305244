import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import 'hammerjs'; // By Angular Material recommended way to import hammer.js. Only has 0.5 kB, so it should be fine.
import * as Sentry from '@sentry/angular';
import {
  isProduction
} from './app/shared/lib/environment/is-production';
import {
  isBeta
} from './app/shared/lib/environment/is-beta';
import {
  getAxErrorEventProcessorForSentry
} from './app/shared/lib/errors/get-ax-error-event-processor-for-sentry';

/**
 * Error logging is only required for production versions of our Angular application, i.e. production or beta. Prevent
 * locally running development versions from sending error reports to Sentry.
 */
if (isProduction() || isBeta()) {
  Sentry.init({
    dsn: 'https://f8e095380274c1d1ff4cd9755120885d@o4507106464825344.ingest.de.sentry.io/4508086756180048',
    integrations: [
      Sentry.browserTracingIntegration(),
      /**
       * Sentry's Session Replay causes performance issues in Angular apps with too many DOM changes (currently caused by MatQuill).
       *
       * Sentry GitHub issue: https://github.com/getsentry/sentry-javascript/issues/12443
       * AutoiXpert Slack documentation: https://autoixpert.slack.com/archives/CB1U7VBBP/p1723220416982309
       */
      // Sentry.replayIntegration({
      //     maskAllText: false,
      //     blockAllMedia: false,
      // }),
    ],
    sendClientReports: false,
    // Performance Monitoring
    tracesSampleRate: isProduction() ? 0.005 : 1.0,

    // Session Replay
    // Deactivated due to performance issues. See above.
    // replaysSessionSampleRate: isProduction() ? 0 : 1.0, // We're only interested in session replay for errors.
    // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

    environment: isProduction()
          ? 'www.autoixpert.de'
          : 'lokal.www.autoixpert.de',
  });

  /**
   * Ensure AxError.code is used as the error name in Sentry instead of "ServerError".
   */
  Sentry.addEventProcessor(getAxErrorEventProcessorForSentry());
}

bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));
