<ax-google-structured-data [json]="googleStructuredData" />
<!--============================================-->
<!-- Hero -->
<!--============================================-->
<section id="hero-section" class="wide-block">
    <div class="container">
        <div class="row">
            <div class="col-sm col-lg-5">
                <div id="hero-job-details-container">
                    <h3 class="additional-headline">Werde Teil unseres Teams</h3>
                    <h1>Senior Angular Developer</h1>
                    <div>Angular, TypeScript, Node.js</div>
                    <div class="label">Start sofort möglich - m/w/d</div>

                    <div id="highlight-tags">
                        <!--********** Remote **********-->
                        <div
                            class="highlight-tag fancy-tooltip"
                            title="Als Senior Developer für Angular, TypeScript und Node.js arbeitest du bei uns weitestgehend remote. Wir treffen uns alle 3-4 Wochen vor Ort in modernen Co-Working Spaces.">
                            <i class="material-icons-outlined">home</i>
                            90 % Remote
                        </div>

                        <!--********** Hours **********-->
                        <div class="highlight-tag fancy-tooltip">
                            <i class="material-icons-outlined">more_time</i>
                            40 h Woche
                        </div>

                        <!--********** Salary **********-->
                        <div
                            class="highlight-tag fancy-tooltip"
                            title="Je besser deine Qualifikation als Senior Developer zu uns passt, desto mehr Gehalt kannst du verdienen.">
                            <i class="material-icons-outlined">euro_symbol</i>
                            75.000 - 95.000 €
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm col-lg-7">
                <img
                    id="hero-image"
                    src="/assets/images/team-with-imac-and-ipad.jpg"
                    alt="autoiXpert Produkt & Founder-Team - Senior Developer für Angular, TypeScript und Node.js" />
            </div>
        </div>
    </div>

    <div>
        <img id="hero-wave" src="/assets/images/multiple-waves.svg" alt="Wellen Hintergrund" />
    </div>
</section>
<!--============================================-->
<!-- END Hero -->
<!--============================================-->

<!--============================================-->
<!-- What We Do -->
<!--============================================-->
<section>
    <div class="container">
        <div class="row justify-content-center">
            <!-- Don't let prettier format the following inline elements (to prevent whitespace between anchor and comma) -->
            <!-- prettier-ignore -->
            <div class="col-sm col-lg-7 text-center">
                <h3 class="additional-headline">Was tun wir?</h3>
                <h2>Stell mit uns die Branche auf den Kopf!</h2>
                <p>
                    autoiXpert ist eine Web-App für eine der spannendsten Branchen
                    Deutschlands: Die Automobil-Branche. Mit autoiXpert erstellen
                    Kfz-Sachverständige Gutachten für Fahrzeuge nach einem Unfallschaden
                    oder bewerten ausgefallene Oldtimer vom
                    <a
                        href="https://www.google.de/search?q=mercedes+sl+300&tbm=isch"
                        target="_blank"
                        rel="noopener">
                        Mercedes SL 300
                    </a>
                    bis zum
                    <a
                        href="https://www.google.de/search?q=aston+martin+db5&tbm=isch"
                        target="_blank"
                        rel="noopener">
                        Aston Martin DB5</a>, dessen Gaspedal schon James Bond aufs Blech drückte.
                </p>
            </div>
        </div>

        <!--********** More Info on Our Product **********-->
        <div class="row justify-content-center">
            <div class="col-sm col-lg-7 text-center">
                <h3 id="more-info-on-our-product-heading">Mehr Infos zu unserem Produkt</h3>

                <div id="more-info-on-our-product-row">
                    <!--********** Home Page **********-->
                    <a href="/" class="more-info-on-our-product-item" target="_blank">
                        <img
                            src="/assets/images/screenshots/homepage-autoixpert-thumbnail.png"
                            alt="Screenshot Startseite autoiXpert" />
                        Startseite
                    </a>

                    <!--********** Brandl Customer Video **********-->
                    <a
                        href="https://www.youtube.com/@autoiXpert"
                        class="more-info-on-our-product-item"
                        target="_blank"
                        rel="noopener">
                        <img
                            src="/assets/images/screenshots/brandl-video-thumbnail.png"
                            alt="Screenshot Brandl Video" />
                        YouTube
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!--============================================-->
<!-- END What We Do -->
<!--============================================-->

<!--============================================-->
<!-- Who should work at autoiXpert? -->
<!--============================================-->
<section id="who-should-work-at-autoixpert-section" class="dark-section">
    <div class="container">
        <div class="row justify-content-center align-items-center">
            <div class="col col-lg-6 order-2 order-lg-1">
                <!--********** Items **********-->
                <div id="who-should-work-at-autoixpert-items-container">
                    <!--********** High Performer **********-->
                    <div class="who-should-work-at-autoixpert-item">
                        <i class="material-icons-outlined text-with-dawn-blue-gradient">emoji_events</i>
                        <div>Du bist ein Leistungsträger.</div>
                    </div>

                    <!--********** Change Industry **********-->
                    <div class="who-should-work-at-autoixpert-item">
                        <i class="material-icons-outlined text-with-dawn-blue-gradient">rocket_launch</i>
                        <div>Du willst eine ganze Branche verändern.</div>
                    </div>

                    <!--********** Smart **********-->
                    <div class="who-should-work-at-autoixpert-item">
                        <i class="material-icons-outlined text-with-dawn-blue-gradient">lightbulb</i>
                        <div>Du bist gut in allem, was du dir vornimmst.</div>
                    </div>

                    <!--********** Team Player **********-->
                    <div class="who-should-work-at-autoixpert-item">
                        <i class="material-icons-outlined text-with-dawn-blue-gradient">sports_soccer</i>
                        <div>
                            Du bist ein
                            <br />
                            Team-Player.
                        </div>
                    </div>
                </div>
            </div>

            <div class="col col-lg-6 order-1 order-lg-2">
                <div id="who-should-work-at-autoixpert-text-container">
                    <h2 class="text-with-dawn-blue-gradient">Wer sollte bei autoiXpert arbeiten?</h2>
                    <p>
                        autoiXpert definiert sich über Lust auf Arbeit, persönliches Wachstum und dem Streben nach
                        hervorragender Software.
                    </p>
                    <p>
                        Wenn du den Drive hast eine ganze Branche auf den Kopf zu stellen und ein Leistungsträger mit
                        erstklassigen Fähigkeiten bist, bist du genau der oder die Richtige für unser Team.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<!--============================================-->
<!-- END Who should work at autoiXpert? -->
<!--============================================-->

<!--============================================-->
<!-- Qualifications -->
<!--============================================-->
<section class="section-with-background">
    <div class="container">
        <div class="row justify-content-center mb-4">
            <div class="col-sm text-center">
                <h3 class="additional-headline">Deine Qualifikationen</h3>
                <h2>Was solltest du mitbringen?</h2>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-7">
                <!--============================================-->
                <!-- Main Qualifications -->
                <!--============================================-->

                <!--********** TypeScript **********-->
                <div id="qualification-typescript" class="qualification-item">
                    <img
                        src="/assets/images/logos/typescript.svg"
                        alt="TypeScript Logo"
                        title="Für einen Senior Developer ist die sehr gute Kenntnis von TypeScript essenziell." />
                    <div class="qualification-item-text monospace">const newTeamMember: BadAssDev = you;</div>
                    <span
                        class="help-indicator fancy-tooltip"
                        title="Du gehst 100% sicher mit allen gängigen JavaScript-Features sowie TypeScripts Interfaces, Klassen und Operatoren um. Du kannst z. B. auch Funktionen mit detaillierten Parameter- und Return-Types eigenständig aufsetzen.">
                        ?
                    </span>
                </div>

                <!--********** Angular **********-->
                <div id="qualification-angular" class="qualification-item">
                    <img
                        src="/assets/images/logos/angular.svg"
                        alt="Angular Logo"
                        title="Für einen Senior Developer ist die sehr gute Kenntnis von Angular unerlässlich." />
                    <!-- prettier-ignore -->
                    <div class="qualification-item-text">
                        Du bist fließend in Wort & Schrift in
                        <strong>Angular</strong>.
                    </div>
                    <span
                        class="help-indicator fancy-tooltip"
                        title="Du kannst eigene Module, Components und Directives schreiben. Zentrale Konzepte wie Dependency Injection und View Encapsulation kannst du erklären.">
                        ?
                    </span>
                </div>

                <!--********** Node.js **********-->
                <div id="qualification-nodejs" class="qualification-item">
                    <img
                        src="/assets/images/logos/nodejs-logo-schwarz.png"
                        alt="Node.js Logo"
                        title="Für einen Senior Developer ist die sehr gute Kenntnis von Node.js essenziell." />

                    <!-- Don't let prettier format the following inline elements (to prevent whitespace between "Node" and dash) -->
                    <!-- prettier-ignore -->
                    <div class="qualification-item-text">
                        Du kannst <strong>Node</strong>-Applikationen aufsetzen und sicher mit ihnen umgehen.
                    </div>
                    <span
                        class="help-indicator fancy-tooltip"
                        title="Du hast schon mehrere Node-Backends geschrieben oder daran mitgearbeitet. Die Logik hinter Frameworks wie Express ist dir bekannt. Goldene Pluspunkte gibt's, wenn du optional auch schon mit Websockets oder FeathersJS gearbeitet hast.">
                        ?
                    </span>
                </div>
                <!--============================================-->
                <!-- END Main Qualifications -->
                <!--============================================-->

                <!--============================================-->
                <!-- Side Skills -->
                <!--============================================-->
                <div class="label"></div>
                <!--********** Eye for Design **********-->
                <div id="qualification-design" class="qualification-item">
                    <img
                        src="/assets/images/icons/color-palette-full-color.png"
                        alt="Farbpalette Icon"
                        title="Für einen Senior Developer ist es wichtig, ein Auge für ein gutes User Interface zu haben." />
                    <div class="qualification-item-text">
                        Du hast ein
                        <strong>Auge</strong>
                        für einfach zu bedienende Software.
                    </div>
                    <span
                        class="help-indicator fancy-tooltip"
                        title="Wir geben alles, damit Kunden unsere Software gerne benutzen. Dafür ist es wichtig, dass die Software selbsterklärend ist und gut aussieht 😎 Das solltest du beim Coden fortführen können.">
                        ?
                    </span>
                </div>

                <!--********** MongoDB **********-->
                <div id="qualification-mongodb" class="qualification-item">
                    <img
                        src="/assets/images/logos/mongodb-leaf.png"
                        alt="Farbpalette Icon"
                        title="Als Senior Developer kennst du dich bestenfalls schon gut mit MongoDB aus." />
                    <div class="qualification-item-text">
                        Bestenfalls kennst du dich gut mit
                        <strong>MongoDB</strong>
                        Queries & Aggregations aus.
                    </div>
                    <span
                        class="help-indicator fancy-tooltip"
                        title="Du kannst Daten für bestimmte Kunden abfragen (z. B. fürs Debuggen) oder ganze Aggregations für eine neue Auswertung schreiben. Damit zauberst du unseren Nutzern ein Lächeln ins Gesicht!">
                        ?
                    </span>
                </div>
                <!--============================================-->
                <!-- END Side Skills -->
                <!--============================================-->

                <!--============================================-->
                <!-- Soft Skills -->
                <!--============================================-->
                <div id="soft-skills">
                    <!--********** Languages **********-->
                    <div class="soft-skill-item">
                        <div class="soft-skill-icon-container">
                            <img src="/assets/images/flags.png" alt="Deutsch & Englisch Flaggen" />
                        </div>
                        <!-- prettier-ignore -->
                        <div>
                            Du sprichst
                            <strong>hervorragend Deutsch (C2)</strong>
                            und erfüllst das
                            <strong>C1-Niveau in Englisch</strong>.
                        </div>
                    </div>

                    <!--********** Motivation **********-->
                    <div class="soft-skill-item">
                        <div class="soft-skill-icon-container">
                            <img src="/assets/images/sunglasses-emoji.png" alt="Coolness" />
                        </div>
                        <div>Du bist motiviert, hast ein abgeschlossenes Studium und bist ein cooler Kollege.</div>
                    </div>
                </div>
                <!--============================================-->
                <!-- END Soft Skills -->
                <!--============================================-->

                <!--                <div id="apply-anyways">-->
                <!--                    Bewirb dich auch, wenn du nicht alles zu 100 % erfüllst.-->
                <!--                </div>-->
            </div>
        </div>
    </div>
</section>
<!--============================================-->
<!-- END Qualifications -->
<!--============================================-->

<!--============================================-->
<!-- Tasks -->
<!--============================================-->
<section id="tasks-section">
    <div class="container">
        <div class="row mb-5">
            <div class="col-sm text-center">
                <h3 class="additional-headline">Was du bei uns tun wirst</h3>
                <h2>Deine Aufgaben</h2>
                In einem kleinen dynamischen Team braucht es keine starren Silos.
                <br class="d-none d-lg-block" />
                Freu dich stattdessen auf vielfältige spannende Aufgaben!
            </div>
        </div>

        <div class="row mb-5 justify-content-center">
            <!--********** Develop New Features **********-->
            <div class="col-lg-4 mb-5">
                <div class="list-item-with-icon-container">
                    <div class="list-item-icon-container">
                        <i class="material-icons-outlined text-with-blue-gradient">auto_awesome</i>
                    </div>
                    <h4 class="list-item-title">Entwicklung neuer Features | 80 %</h4>
                    <div class="label small-text text-center">
                        Du kannst coden. Du wirst coden.
                        <br />
                        Arbeite im
                        <a href="/team" target="_blank">Team</a>
                        mit 3 Devs.
                    </div>
                </div>
            </div>

            <!--********** Product Management **********-->
            <div class="col-lg-4 mb-5">
                <div class="list-item-with-icon-container">
                    <div class="list-item-icon-container small-list-item-container">
                        <i class="material-icons-outlined text-with-blue-gradient">question_answer</i>
                    </div>
                    <h4 class="list-item-title">Produktmanagement im Team | 20 %</h4>
                    <div class="label small-text text-center">
                        Entscheide mit uns und unseren
                        <br class="d-none d-lg-block" />
                        Kunden, was umgesetzt wird.
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="customer-contact-container" class="container mb-5">
        <div class="row text-center text-lg-left">
            <div class="col-lg">
                <div id="customer-contact-image-column">
                    <img
                        src="/assets/images/audatex-automechanika.jpg"
                        alt="Ein paar unserer Team-Mitglieder mit Audatex auf der Automechanika"
                        title="Foto: Andi auf dem Stand unseres strategischen Partners Solera Audatex auf der Automechanika in Frankfurt." />
                </div>
            </div>

            <div id="customer-contact-text-column" class="col-lg-7">
                <div id="customer-contact-text">
                    <h3 id="customer-contact-heading" class="text-with-blue-gradient">Das Ohr immer am Markt</h3>
                    <p>
                        Jeder von uns berät mindestens einen halben Tag die Woche unsere Kunden per Telefon & E-Mail,
                        damit wir immer genau wissen, was Kunden & Interessenten brauchen.
                    </p>
                    <p>
                        Mit unseren Kunden macht der Kontakt Spaß, u. a. weil man in unserer Branche immer einen
                        lockeren Spruch auf den Lippen hat.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row justify-content-center">
            <div class="col text-center">
                <div class="label">
                    Andi und unsere strategischen Partner von Audatex auf der Automechanika in Frankfurt.
                </div>
            </div>
        </div>
    </div>
</section>
<!--============================================-->
<!-- END Tasks -->
<!--============================================-->

<!--============================================-->
<!-- Location -->
<!--============================================-->
<section id="location-section" class="section-with-background">
    <div class="container">
        <div class="row align-items-center text-center text-lg-left">
            <div class="col-sm col-lg-8">
                <img class="mb-5" src="/assets/images/icons/place.png" alt="Place Icon" />
                <h3 class="additional-headline">Remote arbeiten & Team-Events</h3>
                <h2>Deine Location</h2>
                <p>Wir arbeiten remote im Süden Deutschlands: in Frankfurt, Stuttgart und München.</p>
                <p>
                    Am besten wohnst du irgendwo in
                    <strong>West-, Mittel- oder Süddeutschland</strong>, damit du
                    <br class="d-none d-lg-block" />
                    ohne weite Anreise bei jedem Co-Working (alle 3-4 Wochen für zwei Tage in
                    <br class="d-none d-lg-block" />
                    wechselnden Städten) dabei sein kannst.
                    <span
                        class="help-indicator fancy-tooltip"
                        title="Wir mieten uns zu den Co-Workings in moderne Co-Working Spaces in Frankfurt, Stuttgart, Würzburg, München und Ulm ein, je nachdem was dem Team gerade am besten passt und worauf wir am meisten Lust haben. Die Liste könnte sich auch um deinen Standort erweitern! Unser Mikrobüro in Burgau zwischen Augsburg und Ulm besuchen wir nur äußerst selten.">
                        ?
                    </span>
                </p>
            </div>

            <div class="col-sm col-lg-4">
                <img
                    src="/assets/images/office-photo-mark-and-robin.jpg"
                    alt="Senior Developer Mark und Junior Developer Robin beim Co-Working in Stuttgart"
                    title="Senior Developer Mark und Junior Developer Robin beim Co-Working in Stuttgart" />
            </div>
        </div>
    </div>
</section>
<!--============================================-->
<!-- END Location -->
<!--============================================-->

<!--============================================-->
<!-- Amenities -->
<!--============================================-->
<section id="amenities-section">
    <div class="container">
        <div class="row mb-5">
            <div class="col-sm text-center">
                <h3 class="additional-headline">Was dich erwartet</h3>
                <h2>Was ist sonst noch ganz nice?</h2>
                <p>Damit du dein Bestes geben kannst, schaffen wir dir den besten Rahmen.</p>
            </div>
        </div>

        <!--============================================-->
        <!-- List -->
        <!--============================================-->
        <div class="row">
            <!--********** IT Budget **********-->
            <div class="col-lg-4 mb-5">
                <div class="list-item-with-icon-container">
                    <div class="list-item-icon-container">
                        <img src="/assets/images/icons/devices.png" alt="Laptop & Smartphone Icon" />
                    </div>
                    <h4 class="list-item-title">3.000 € IT-Budget</h4>
                    <div class="label small-text text-center">
                        Für Laptop, Monitor, Headphones,
                        <br class="d-none d-lg-block" />
                        AirPods etc.
                    </div>
                </div>
            </div>

            <!--********** Direct Communication **********-->
            <div class="col-lg-4 mb-5">
                <div class="list-item-with-icon-container">
                    <div class="list-item-icon-container">
                        <img src="/assets/images/icons/record-voice-over.png" alt="Person Talking Icon" />
                    </div>
                    <h4 class="list-item-title">Startup-Spirit</h4>
                    <div class="label small-text text-center">
                        Wir sind schnell und bewegen viel.
                        <br class="d-none d-lg-block" />
                        No-Go-Area für starre Hierarchien.
                    </div>
                </div>
            </div>

            <!--********** Cool Customers **********-->
            <div class="col-lg-4 mb-5">
                <div class="list-item-with-icon-container">
                    <div class="list-item-icon-container">
                        <img src="/assets/images/icons/person-waving.png" alt="Winkender Mann Icon" />
                    </div>
                    <h4 class="list-item-title">Arbeite mit coolen Kunden</h4>
                    <div class="label small-text text-center">
                        Unsere Kunden sind Unternehmer durch
                        <br class="d-none d-lg-block" />
                        und durch, die ihr Handwerk 1A drauf haben.
                    </div>
                </div>
            </div>

            <!--********** Vacation Days **********-->
            <div class="col-lg-4 mb-5">
                <div class="list-item-with-icon-container">
                    <div class="list-item-icon-container small-list-item-container">
                        <i class="material-icons-outlined text-with-blue-gradient" alt="Sonnenschirm Icon">
                            beach_access
                        </i>
                    </div>
                    <h4 class="list-item-title">30 Urlaubstage</h4>
                </div>
            </div>

            <!--********** Flexible Work Hours **********-->
            <div class="col-lg-4 mb-5">
                <div class="list-item-with-icon-container">
                    <div class="list-item-icon-container small-list-item-container">
                        <i class="material-icons-outlined text-with-blue-gradient" alt="WLAN Icon">wifi</i>
                    </div>
                    <h4 class="list-item-title">Flexibles Arbeiten</h4>
                </div>
            </div>

            <!--********** Home Office **********-->
            <div class="col-lg-4 mb-5">
                <div class="list-item-with-icon-container">
                    <div class="list-item-icon-container small-list-item-container">
                        <i class="material-icons-outlined text-with-blue-gradient" alt="Haus Icon">home</i>
                    </div>
                    <h4 class="list-item-title">Home Office</h4>
                </div>
            </div>
        </div>
        <!--============================================-->
        <!-- END List -->
        <!--============================================-->
    </div>
</section>
<!--============================================-->
<!-- END Amenities -->
<!--============================================-->

<!--============================================-->
<!-- Professional and Personal Growth -->
<!--============================================-->
<section id="growth-section">
    <div id="growth-container" class="container">
        <div class="row">
            <div class="col">
                <!--********** Headline **********-->
                <h3 class="additional-headline">Dein Entwicklungsplan</h3>
                <h2 class="text-with-blue-gradient">Berufliche & persönliche Entwicklung</h2>

                <!--********** List **********-->
                <div id="growth-list">
                    <!--********** Life-long Learning **********-->
                    <div class="growth-list-item">
                        <i class="material-icons-outlined growth-list-item-icon text-with-blue-gradient">school</i>
                        <div>
                            Wir leben
                            <strong>life-long Learning</strong>
                            jeden Tag. Außerdem zahlen wir dir sinnvolle Kurse für technische Weiterbildung.
                        </div>
                    </div>

                    <!--********** Curriculum **********-->
                    <div class="growth-list-item">
                        <i class="material-icons-outlined growth-list-item-icon text-with-blue-gradient">menu_book</i>
                        <div>
                            <strong>Maßgeschneiderter Lehrplan</strong>
                            in Abstimmung mit deinem Mentor.
                        </div>
                    </div>

                    <!--********** Onboarding **********-->
                    <div class="growth-list-item">
                        <i class="material-icons-outlined growth-list-item-icon text-with-blue-gradient">
                            rocket_launch
                        </i>
                        <div>
                            <strong>Onboarding:</strong>
                            Wir geben alles, damit du schnell auf Augenhöhe mit Kollegen & Kunden sprechen kannst.
                        </div>
                    </div>

                    <!--********** Salary **********-->
                    <div class="growth-list-item">
                        <i class="material-icons-outlined growth-list-item-icon text-with-blue-gradient">trending_up</i>
                        <div>
                            Wenn du viel für das Team leistest, wächst dein
                            <strong>Gehalt</strong>
                            mit dir.
                        </div>
                    </div>
                </div>

                <i id="trending-up-icon" class="material-icons-outlined">trending_up</i>
            </div>
        </div>
    </div>
</section>
<!--============================================-->
<!-- END Professional and Personal Growth -->
<!--============================================-->

<!--============================================-->
<!-- Application Process -->
<!--============================================-->
<section id="application-process-section" class="section-with-background">
    <div class="container">
        <div class="row mb-5">
            <div class="col-sm text-center">
                <h3 class="additional-headline">Was wir von dir brauchen</h3>
                <h2>Deine Bewerbung bei uns</h2>
                <p>Erzähl uns etwas von dir. Zeig uns deine Erfahrung und deine Motivation!</p>
            </div>
        </div>

        <div class="row justify-content-center mb-5">
            <div class="col-lg-4">
                <div class="checklist-item">
                    <img
                        class="checklist-item-icon"
                        src="/assets/images/icons/check-circle.png"
                        alt="Checklisten Icon" />
                    Lebenslauf
                </div>

                <!--                <div class="checklist-item">-->
                <!--                    <img class="checklist-item-icon" src="/assets/images/icons/check-circle.png" alt="Checklisten Icon">-->
                <!--                    Zeugnisse (Uni oder Arbeit)-->
                <!--                </div>-->

                <div class="checklist-item">
                    <img
                        class="checklist-item-icon"
                        src="/assets/images/icons/check-circle.png"
                        alt="Checklisten Icon" />
                    Deine Erfahrungen mit unserem Tech Stack
                </div>

                <div class="checklist-item">
                    <img
                        class="checklist-item-icon"
                        src="/assets/images/icons/check-circle.png"
                        alt="Checklisten Icon" />
                    Wann kannst du bei uns anfangen?
                </div>
            </div>
        </div>

        <!--============================================-->
        <!-- Application Process Mobile -->
        <!--============================================-->
        <div id="application-process-mobile" class="row justify-content-center mb-5">
            <div class="col-lg-4">
                <h4>So läuft der Bewerbungsprozess ab</h4>

                <div class="checklist-item">
                    <i class="application-process-icon material-icons-outlined">local_library</i>
                    Wir screenen deine Bewerbung
                </div>

                <div class="checklist-item">
                    <i class="application-process-icon material-icons-outlined">videocam</i>
                    Zoom-Interview
                </div>

                <div class="checklist-item">
                    <i class="application-process-icon material-icons-outlined">local_cafe</i>
                    Persönliches Treffen & Tech Test
                    <span
                        class="help-indicator"
                        title="Wir lernen uns locker in einer schicken Bar kennen und prüfen deine Coding Skills in 1-2 h.">
                        ?
                    </span>
                </div>
            </div>
        </div>
        <!--============================================-->
        <!-- END Application Process Mobile -->
        <!--============================================-->

        <!--============================================-->
        <!-- Application Process Larger Screens -->
        <!--============================================-->
        <div id="application-process-larger-screens" class="row justify-content-center">
            <div class="col-lg-6">
                <h4 class="mb-3 text-center">So läuft der Bewerbungsprozess ab</h4>

                <!--********** Process Line (Horizontal Line) **********-->
                <div id="application-process-timeline">
                    <!--********** Application Screening **********-->
                    <div id="application-screening" class="application-process-larger-screens-item">
                        <div class="application-process-larger-screens-location-marker">
                            <i
                                class="application-process-larger-screens-icon material-icons-outlined text-with-blue-gradient">
                                local_library
                            </i>
                        </div>
                        <div class="application-process-larger-screens-item-label">Wir screenen deine Bewerbung</div>
                    </div>

                    <!--********** Zoom Interview **********-->
                    <div id="zoom-interview" class="application-process-larger-screens-item">
                        <div class="application-process-larger-screens-location-marker">
                            <i
                                class="application-process-larger-screens-icon material-icons-outlined text-with-blue-gradient">
                                videocam
                            </i>
                        </div>
                        <div class="application-process-larger-screens-item-label">
                            Zoom-
                            <br />
                            Interview
                        </div>
                    </div>

                    <!--********** Personal Meeting **********-->
                    <div id="personal-meeting" class="application-process-larger-screens-item">
                        <div class="application-process-larger-screens-location-marker">
                            <i
                                class="application-process-larger-screens-icon material-icons-outlined text-with-blue-gradient">
                                sports_bar
                            </i>
                        </div>
                        <div class="application-process-larger-screens-item-label">
                            Persönliches Treffen & Tech Test
                            <span
                                class="help-indicator fancy-tooltip"
                                title="Wir lernen uns locker in einer schicken Bar kennen und prüfen deine Coding Skills in 1-2 h.">
                                ?
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--============================================-->
        <!-- END Application Process Larger Screens -->
        <!--============================================-->

        <div class="row justify-content-center">
            <div class="col-lg-10 text-center">
                <!--********** Picture of contact person **********-->
                <div id="contact-person-container">
                    <img src="/assets/images/people/steffen-2022.jpg" alt="Steffen Langer" />
                    <div id="contact-person-name-column">
                        Dein Ansprechpartner
                        <h4>Steffen Langer</h4>
                        +49 8222 91 88 99 0
                        <div class="d-flex align-items-center">
                            <a
                                href="https://www.linkedin.com/in/steffenlanger/"
                                class="mr-1"
                                target="_blank"
                                rel="noopener">
                                <img
                                    src="/assets/images/logos/linked-in-logo.png"
                                    alt="LinkedIn Logo"
                                    title="Öffne das Profil des Senior Software Architects Steffen Langer" />
                            </a>

                            <a
                                href="https://www.xing.com/profile/Steffen_Langer16/cv"
                                class="mr-1"
                                target="_blank"
                                rel="noopener">
                                <img
                                    src="/assets/images/logos/xing-logo.png"
                                    alt="XING Logo"
                                    title="Öffne das Profil des Senior Software Architects Steffen Langer" />
                            </a>
                        </div>
                    </div>
                </div>

                <a
                    class="exclamation-box-button button-solid"
                    href="mailto:bewerbung@autoixpert.de"
                    target="_blank"
                    rel="noopener">
                    Bewerbung starten
                </a>
            </div>
        </div>
    </div>
</section>
<!--============================================-->
<!-- END Application Process -->
<!--============================================-->
