<ax-webinar-hero
    [webinarDate]="webinarDate"
    [webinarTitleShort]="webinarTitleShort"
    [price]="price"
    [duration]="duration"></ax-webinar-hero>

<ax-webinar-agenda
    [maximumNumberOfParticipants]="maximumNumberOfParticipants"
    [webinarContents]="webinarContents"
    [webinarTitle]="webinarTitle">
    <p>
        Das Webinar für Einsteiger in die Audatex Schadenkalkulation zeigt zunächst die klassische Schadenkalkulation an
        einem praktischen Beispiel mit den erforderlichen Optionen wie erneuern, instandsetzen und lackieren. An diesem
        Beispiel werden weitergehende Themen wie die Verwendung von individuellen (Nicht-Standard-)Positionen und der
        IFL Liste, aber auch die Mutationen einzelner Positionen diskutiert. Im weiteren Verlauf wird ein gesonderter
        Blick auf die Einstellungen, sowie die Möglichkeit des Anlegens büroindividueller Positionen besprochen. In
        einem weiteren Schritt wird die Möglichkeit der Durchführung einer Phantomkalkulation beziehungsweise einer
        manuellen, freien Kalkulation an einem Beispiel erarbeitet.
    </p>
</ax-webinar-agenda>

<ax-webinar-target-group [targetGroupLevel]="targetGroupLevel">
    Du solltest teilnehmen, wenn du in das Thema Audatex-Kalkulation einsteigen oder dein Grundlagenwissen auffrischen
    möchtest.
</ax-webinar-target-group>

<ax-webinar-instructor-section [instructors]="instructors"></ax-webinar-instructor-section>

<ax-webinar-signup-section
    [webinarDate]="webinarDate"
    [duration]="duration"
    [price]="price"
    [webinarTitle]="webinarTitle"
    [webinarContents]="webinarContents"
    [instructor]="instructors[0].name"
    [eventOverbooked]="eventOverbooked"></ax-webinar-signup-section>
