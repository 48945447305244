<div id="registration-container" [class.show]="currentStep">
    <div id="registration-header-logo-container" class="dark">
        @if ((currentStep && (currentStep.id === 'enterEmail' || currentStep.id === 'confirmEmail')) || isMobile()) {
            <a href="/" @fadeInAndOut>
                <img
                    id="registration-header-logo-dark"
                    class="registration-header-logo"
                    src="/assets/images/autoixpert-logo.svg"
                    alt="autoiXpert Logo" />
            </a>
        }
        @if (currentStep && currentStep.id !== 'enterEmail' && currentStep.id !== 'confirmEmail' && !isMobile()) {
            <a href="/" @fadeInAndOut>
                <img
                    id="registration-header-logo-light"
                    class="registration-header-logo"
                    src="/assets/images/logo-autoixpert-invertiert.svg"
                    alt="autoiXpert Logo" />
            </a>
        }
    </div>

    @if (
        currentStep &&
        ['setPassword', 'confirmEmail', 'phoneNumber', 'phoneNumberVerificationCode', 'aboutYou'].includes(
            currentStep.id
        )
    ) {
        <div id="back-button-container">
            <i class="material-icons" title="Zurück" (click)="returnToPreviousStep()">arrow_back</i>
        </div>
    }

    <!--********** Large diagonal image **********-->
    @if (
        currentStep &&
        [
            'enterEmail',
            'confirmEmail',
            'emailConfirmed',
            'contactDetails',
            'phoneNumber',
            'phoneNumberVerificationCode'
        ].includes(currentStep.id)
    ) {
        <div
            class="image-badge-container"
            @moveOutLeft
            [ngClass]="{
                left:
                    currentStep &&
                    (currentStep.id === 'contactDetails' ||
                        currentStep.id === 'phoneNumber' ||
                        currentStep.id === 'phoneNumberVerificationCode')
            }">
            <div class="image-badge">&nbsp;</div>
        </div>
    }

    <!--********** Images in the corner **********-->
    @if (currentStep && ['setPassword', 'registrationSuccessful', 'aboutYou'].includes(currentStep.id) && !isMobile()) {
        <div id="image-top-left-corner" class="image-in-corner" @moveInFromTopLeft>&nbsp;</div>
    }

    <div
        id="image-bottom-right-corner-container"
        *ngIf="!isMobile()"
        [style.display]="
            currentStep && ['setPassword', 'registrationSuccessful', 'aboutYou'].includes(currentStep.id)
                ? 'block'
                : 'none'
        ">
        @if (currentStep && ['setPassword', 'registrationSuccessful', 'aboutYou'].includes(currentStep.id)) {
            <div id="image-bottom-right-corner" class="image-in-corner" @moveInFromBottomRight>&nbsp;</div>
        }
    </div>

    <!--============================================-->
    <!-- Enter E-Mail -->
    <!--============================================-->
    @if (currentStep && currentStep.id === 'enterEmail') {
        <div id="registration-welcome-step" class="registration-step-container registration-step-left" @fadeInAndOut>
            <h2>Herzlich Willkommen bei autoiXpert.</h2>
            <!--********** Sonderdeals **********-->
            @if (specialOffer && specialOffer.includes('tuevRheinland')) {
                <div class="special-offer-intro-container">
                    <div class="label">Code eingelöst:</div>
                    <div class="special-offer-logo-container">
                        <img src="/assets/images/logos/tuev-rheinland-logo.png" alt="Logo TÜV Rheinland" />
                    </div>
                </div>
                <div class="intro">
                    <p>
                        Die Registrierung ist kostenfrei. Nach deiner Prüfung hast du die Wahl, ob du autoiXpert
                        kosten&shy;pflichtig weiter nutzen möchtest.
                        <br />
                        Keine automatische Verlängerung.
                    </p>
                </div>
            } @else {
                <div class="intro">
                    <p>
                        Die Registrierung ist kostenfrei. Nach 30 Tagen hast du die Wahl, ob du autoiXpert
                        kosten&shy;pflichtig weiter nutzen möchtest.
                        <br />
                        Keine automatische Verlängerung.
                    </p>
                </div>
            }
            <form
                id="registration-welcome-step-form"
                class="registration-form"
                (submit)="proceedToEmailConfirmation(); $event.preventDefault()">
                <input
                    type="email"
                    class="newsletter-input"
                    [(ngModel)]="email"
                    [ngModelOptions]="{ standalone: true }"
                    placeholder="E-Mail"
                    autofocus
                    required />
                <button
                    type="submit"
                    [ngClass]="{
                        disallowed: !sendingWelcomeFormAllowed() || emailSubmissionPending,
                        disabled: emailSubmissionPending
                    }"
                    [title]="sendingWelcomeFormAllowed() ? '' : 'Bitte gib deine E-Mail-Adresse an.'"
                    class="primary-button newsletter-input">
                    @if (emailSubmissionPending) {
                        <img class="loading-indicator" src="/assets/images/loading-indicator-white.svg" alt="" />
                    }
                    @if (!emailSubmissionPending) {
                        <span>Weiter</span>
                    }
                </button>
            </form>
        </div>
    }
    <!--============================================-->
    <!-- END Enter E-Mail -->
    <!--============================================-->

    <!--============================================-->
    <!-- Confirm E-Mail -->
    <!--============================================-->
    @if (currentStep && currentStep.id === 'confirmEmail') {
        <div
            id="registration-confirm-email-step"
            class="registration-step-container registration-step-left"
            @fadeInAndOut>
            <h2>E-Mail-Adresse bestätigen</h2>
            <div class="intro">
                <p>
                    Bitte klicke den Link, den wir soeben an
                    <strong style="color: #333">{{ email }}</strong>
                    geschickt haben.
                </p>
            </div>
            <div id="no-email-received" class="intro">
                <p>
                    Keine E-Mail erhalten?
                    <br />
                    Bitte Spam-Ordner prüfen oder
                    <a href="javascript:void(0)" (click)="resendConfirmationEmail()">Link erneut senden</a>
                </p>
            </div>
        </div>
    }
    <!--============================================-->
    <!-- END Confirm E-Mail -->
    <!--============================================-->

    <!--============================================-->
    <!-- E-Mail Confirmed -->
    <!--============================================-->
    @if (currentStep && currentStep.id === 'emailConfirmed') {
        <div
            id="registration-email-confirmed-step"
            class="registration-step-container registration-step-left"
            @fadeInAndOut>
            <h2>E-Mail bestätigt</h2>
            <div id="email-confirmed-icon-container">
                <i class="material-icons">check</i>
            </div>
            <button id="email-confirmed-button" class="primary-button newsletter-input" (click)="proceedToNextStep()">
                Weiter
            </button>
        </div>
    }
    <!--============================================-->
    <!-- END E-Mail Confirmed -->
    <!--============================================-->

    <!--============================================-->
    <!-- Contact Details -->
    <!--============================================-->
    @if (currentStep && currentStep.id === 'contactDetails') {
        <div
            id="registration-contact-details-step"
            class="registration-step-container registration-step-right"
            @fadeInAndOut>
            <h2>Welche Kontaktdaten dürfen wir hinterlegen?</h2>
            <form
                id="registration-contact-details-step-form"
                class="registration-form"
                (submit)="proceedToNextStep(); $event.preventDefault()">
                <input
                    type="text"
                    class="newsletter-input"
                    [(ngModel)]="firstName"
                    [ngModelOptions]="{ standalone: true }"
                    placeholder="Vorname"
                    autofocus
                    required />
                <input
                    type="text"
                    class="newsletter-input"
                    [(ngModel)]="lastName"
                    [ngModelOptions]="{ standalone: true }"
                    placeholder="Nachname"
                    required />
                <!--********** Phone number (unverified) - Additional user only **********-->
                @if (userIsBeingInvited) {
                    <input
                        type="tel"
                        class="newsletter-input"
                        [(ngModel)]="phone"
                        [ngModelOptions]="{ standalone: true }"
                        placeholder="Telefon"
                        required />
                }
                <div id="office-contact-details-container">
                    <input
                        type="text"
                        class="newsletter-input"
                        [(ngModel)]="organization"
                        [ngModelOptions]="{ standalone: true }"
                        placeholder="Name deines Büros"
                        required />
                    <input
                        type="text"
                        class="newsletter-input"
                        [(ngModel)]="streetAndHouseNumber"
                        [ngModelOptions]="{ standalone: true }"
                        placeholder="Straße & Hausnummer"
                        required />
                    <div id="zip-and-city-container">
                        <input
                            id="zip-input"
                            type="text"
                            class="newsletter-input"
                            [(ngModel)]="zip"
                            [ngModelOptions]="{ standalone: true }"
                            placeholder="PLZ"
                            required />
                        <input
                            type="text"
                            class="newsletter-input"
                            [(ngModel)]="city"
                            [ngModelOptions]="{ standalone: true }"
                            placeholder="Ort"
                            required />
                    </div>
                </div>
                @if (!userIsBeingInvited) {
                    <div class="additional-info">
                        Alles richtig geschrieben?
                        <br />
                        Während des Tests kannst du die Daten nicht verändern.
                    </div>
                }
                <input
                    type="submit"
                    value="Weiter"
                    [ngClass]="{ disallowed: !sendContactDataFormAllowed() }"
                    [title]="sendContactDataFormAllowed() ? '' : 'Bitte gib deine Adressdaten vollständig ein.'"
                    class="primary-button newsletter-input" />
                <div class="privacy-statement-link">
                    <a href="/datenschutz" target="_blank" rel="noopener">Datenschutzerklärung</a>
                </div>
            </form>
        </div>
    }
    <!--============================================-->
    <!-- END Contact Details -->
    <!--============================================-->

    <!--============================================-->
    <!-- Phone Number -->
    <!--============================================-->
    @if (currentStep && currentStep.id === 'phoneNumber') {
        <div
            id="registration-phone-number-step"
            class="registration-step-container registration-step-right"
            @fadeInAndOut>
            <h2>Wie lautet deine Mobilnummer?</h2>
            <form
                id="registration-phone-number-step-form"
                class="registration-form"
                (submit)="sendPhoneNumberVerificationCode(true); $event.preventDefault()">
                <input
                    type="tel"
                    class="newsletter-input"
                    [(ngModel)]="phone"
                    [ngModelOptions]="{ standalone: true }"
                    placeholder="Telefon"
                    required />
                <!--********** Button **********-->
                <button
                    type="submit"
                    [ngClass]="{ disabled: phoneNumberVerificationCodeSending }"
                    class="primary-button newsletter-input">
                    @if (phoneNumberVerificationCodeSending) {
                        <img class="loading-indicator" src="/assets/images/loading-indicator-white.svg" alt="" />
                    }
                    @if (!phoneNumberVerificationCodeSending) {
                        <span>Code per SMS senden</span>
                    }
                </button>
                <!--********** Reason Note **********-->
                <div id="why-phone-number-note" class="label">
                    Wir stellen dir Kalkulationen und 5 VINs kostenfrei zur Verfügung. Deshalb möchten wir wissen, wer
                    du bist.
                </div>
                <div class="privacy-statement-link">
                    <a href="/datenschutz" target="_blank" rel="noopener">Datenschutzerklärung</a>
                </div>
            </form>
        </div>
    }
    <!--============================================-->
    <!-- END Phone Number -->
    <!--============================================-->

    <!--============================================-->
    <!-- Phone Number Validation Code -->
    <!--============================================-->
    @if (currentStep && currentStep.id === 'phoneNumberVerificationCode') {
        <div
            id="registration-phone-number-validation-code-step"
            class="registration-step-container registration-step-right"
            @fadeInAndOut>
            <h2>Bitte bestätige den Code</h2>
            <div id="phone-number-validation-code-recipient-message">
                Eine SMS wurde an
                <strong>{{ phoneNumberTheSmsCodeHasBeenSentTo }}</strong>
                geschickt.
            </div>
            <form
                id="registration-phone-number-validation-code-step-form"
                class="registration-form"
                (submit)="checkPhoneNumberVerificationCode(); $event.preventDefault()">
                <input
                    type="text"
                    inputmode="numeric"
                    autocomplete="one-time-code"
                    pattern="\d{4}"
                    class="newsletter-input"
                    [(ngModel)]="phoneNumberVerificationCode"
                    [ngModelOptions]="{ standalone: true }"
                    placeholder="4-stelliger Code"
                    required />
                <!--********** Button **********-->
                <button
                    type="submit"
                    [ngClass]="{
                        disallowed: !isPhoneNumberVerificationCodeValid(),
                        disabled: phoneNumberVerificationCodeCheckPending
                    }"
                    [title]="
                        isPhoneNumberVerificationCodeValid()
                            ? ''
                            : 'Bitte gib den vierstelligen Code aus der SMS vollständig ein.'
                    "
                    class="primary-button newsletter-input">
                    @if (phoneNumberVerificationCodeCheckPending) {
                        <img class="loading-indicator" src="/assets/images/loading-indicator-white.svg" alt="" />
                    }
                    @if (!phoneNumberVerificationCodeCheckPending) {
                        <span>Weiter</span>
                    }
                </button>
                <div id="resend-sms-validation-code">
                    <a href="javascript:void(0)" (click)="sendPhoneNumberVerificationCode(false)">Code erneut senden</a>
                </div>
            </form>
        </div>
    }
    <!--============================================-->
    <!-- END Phone Number Validation Code -->
    <!--============================================-->

    <!--============================================-->
    <!-- About You Data -->
    <!--============================================-->
    @if (currentStep && currentStep.id === 'aboutYou') {
        <div
            id="registration-about-you-step"
            class="registration-step-container registration-step-center"
            @fadeInAndOut>
            <h2 id="about-you-heading">Fast geschafft.</h2>
            <div id="about-you-tagline">Erzähl uns noch etwas von dir.</div>
            <form
                id="registration-about-you-step-form"
                class="registration-form"
                (submit)="checkAboutYouData(); $event.preventDefault()">
                <div id="about-you-container">
                    <!--********** Company Type **********-->
                    <h3>Tätigkeit</h3>
                    <div class="options-row-wrapper">
                        <div id="company-type-options-row" class="options-row">
                            <!--********** Assessor **********-->
                            <div
                                class="option-container"
                                (click)="selectCompanyType('assessor')"
                                [ngClass]="{ active: companyType === 'assessor' }"
                                title="Bestehende Gutachter oder Neueinsteiger">
                                <i class="material-icons option-image">assignment</i>
                                <div class="option-title">Gutachter</div>
                            </div>
                            <!--********** Garage **********-->
                            <div
                                class="option-container"
                                (click)="selectCompanyType('garage')"
                                [ngClass]="{ active: companyType === 'garage' }"
                                title="Autohäuser & Werkstätten">
                                <i class="material-icons option-image">build</i>
                                <div class="option-title">Autohaus</div>
                            </div>
                            <!--********** Other **********-->
                            <div
                                class="option-container"
                                (click)="selectCompanyType('other')"
                                [ngClass]="{ active: companyType === 'other' }">
                                <i class="material-icons option-image">more_horiz</i>
                                <div class="option-title">Andere</div>
                            </div>
                        </div>
                        @if (companyType === 'garage') {
                            <div id="garage-software-warning">
                                Falls du als Autohaus
                                <strong>Kostenvoranschläge</strong>
                                oder
                                <strong>Fahrzeugbewertungen</strong>
                                anfertigen möchtest, ist autoiXpert die richtige Wahl für dich.
                                <br />
                                <br />
                                Falls du eine Software für deinen Hauptbetrieb suchst, gibt es für Werkstätten &
                                Lackierbetriebe spezialisiertere Software als eine Gutachten-Software wie autoiXpert.
                                <a
                                    href="https://www.google.com/search?q=werkstattsoftware"
                                    target="_blank"
                                    rel="noopener">
                                    Google-Suche
                                </a>
                                <br />
                            </div>
                        }
                    </div>
                    <!--********** Job Experience **********-->
                    <h3>Berufserfahrung</h3>
                    <div class="options-row-wrapper">
                        <div id="job-experience-options-row" class="options-row">
                            <!--********** Existing Company **********-->
                            <div
                                class="option-container"
                                (click)="selectJobExperience('existingCompany')"
                                [ngClass]="{ active: jobExperience === 'existingCompany' }"
                                title="Du hast bereits mehr als 12 Monate ein eigenes Büro.">
                                <i class="material-icons option-image">house</i>
                                <div class="option-title">Experte</div>
                            </div>
                            <!--********** New Company **********-->
                            <div
                                class="option-container"
                                (click)="selectJobExperience('newCompany')"
                                [ngClass]="{ active: jobExperience === 'newCompany' }"
                                title="Büro in Gründung oder jünger als 12 Monate">
                                <i class="material-icons option-image">trending_up</i>
                                <div class="option-title">Einsteiger</div>
                            </div>
                            <!--********** Other **********-->
                            <div
                                class="option-container"
                                (click)="selectJobExperience('other')"
                                [ngClass]="{ active: jobExperience === 'other' }">
                                <i class="material-icons option-image">more_horiz</i>
                                <div class="option-title">Andere</div>
                            </div>
                        </div>
                    </div>
                    <!--********** Marketing Channel **********-->
                    <h3>Wie hast du autoiXpert gefunden?</h3>
                    <div class="options-row-wrapper">
                        <div class="options-row">
                            <!--********** Search **********-->
                            <div
                                class="option-container"
                                (click)="selectMarketingChannel('search')"
                                [ngClass]="{ active: marketingChannel === 'search' }"
                                title="Google, Bing und ähnliche">
                                <i class="material-icons option-image">public</i>
                                <div class="option-title">Online-Suche</div>
                            </div>
                            <!--********** Advertisements **********-->
                            <div
                                class="option-container"
                                (click)="selectMarketingChannel('ads')"
                                [ngClass]="{ active: marketingChannel === 'ads' }"
                                title="Online-Werbung oder in Zeitschriften">
                                <i class="material-icons option-image">photo</i>
                                <div class="option-title">Werbung</div>
                            </div>
                            <!--********** Recommendation **********-->
                            <div
                                class="option-container"
                                (click)="selectMarketingChannel('recommendation')"
                                [ngClass]="{ active: marketingChannel === 'recommendation' }"
                                title="Empfehlung von Bekannten oder DAT-Vertrieblern">
                                <i class="material-icons option-image">record_voice_over</i>
                                <div class="option-title">Empfehlung</div>
                            </div>
                            <!--********** Other **********-->
                            <div
                                class="option-container"
                                (click)="selectMarketingChannel('other')"
                                [ngClass]="{ active: marketingChannel === 'other' }">
                                <i class="material-icons option-image">more_horiz</i>
                                <div class="option-title">Andere</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="registration-form-button-container">
                    <button
                        type="submit"
                        class="primary-button newsletter-input"
                        [ngClass]="{ disallowed: !aboutYouDataComplete() }"
                        [title]="aboutYouDataComplete() ? '' : 'Bitte fülle alle Angaben vollständig aus.'">
                        Passwort setzen
                    </button>
                </div>
            </form>
        </div>
    }
    <!--============================================-->
    <!-- END About You Data -->
    <!--============================================-->

    <!--============================================-->
    <!-- Password -->
    <!--============================================-->
    @if (currentStep && currentStep.id === 'setPassword') {
        <div id="registration-password-step" class="registration-step-container registration-step-center" @fadeInAndOut>
            <h2>Passwort setzen</h2>
            <form
                id="registration-password-step-form"
                class="registration-form"
                (submit)="createUserAccount(); $event.preventDefault()">
                <!-- Help the user's password manager to identify the combination of username and password. -->
                <div style="height: 0; width: 0; overflow: hidden" tabindex="-1">
                    <input
                        type="email"
                        [(ngModel)]="email"
                        placeholder="E-Mail"
                        [ngModelOptions]="{ standalone: true }" />
                </div>
                <div id="password-input-container">
                    <input
                        [type]="passwordVisible ? 'text' : 'password'"
                        class="newsletter-input"
                        [(ngModel)]="password"
                        [ngModelOptions]="{ standalone: true }"
                        placeholder="Passwort"
                        autofocus
                        required />
                    @if (!passwordVisible) {
                        <i
                            class="material-icons password-toggle"
                            title="Passwort zeigen"
                            (click)="togglePasswordVisibility()">
                            visibility
                        </i>
                    }
                    @if (passwordVisible) {
                        <i
                            class="material-icons password-toggle"
                            title="Passwort verstecken"
                            (click)="togglePasswordVisibility()">
                            visibility_off
                        </i>
                    }
                </div>
                <div class="intro">
                    Mit einem Klick auf "Account erstellen" erklärst du dich mit unseren
                    <a href="/datenschutz" target="_blank" rel="noopener">Datenschutzbestimmungen</a>
                    und
                    <a href="/nutzungsbedingungen" target="_blank" rel="noopener">Nutzungsbedingungen</a>
                    einverstanden.
                </div>
                <button
                    type="submit"
                    [ngClass]="{
                        disallowed: !setPasswordAllowed() || accountCreationPending,
                        disabled: accountCreationPending
                    }"
                    [title]="setPasswordAllowed() ? '' : 'Bitte gib ein Passwort ein.'"
                    class="primary-button newsletter-input"
                    id="set-password-button">
                    @if (!accountCreationPending) {
                        Account erstellen
                    }
                    @if (accountCreationPending) {
                        <img
                            class="pending-indicator"
                            src="/assets/images/loading-indicator-white.svg"
                            alt="Loading Indicator" />
                    }
                </button>
            </form>
        </div>
    }
    <!--============================================-->
    <!-- END Password -->
    <!--============================================-->

    <!--============================================-->
    <!-- Registration Successful -->
    <!--============================================-->
    @if (currentStep && currentStep.id === 'registrationSuccessful') {
        <div
            id="registration-successful-step"
            class="registration-step-container registration-step-center"
            @fadeInAndOut>
            <h2>Vielen Dank für deine Registrierung!</h2>
            <div class="intro">
                <button class="primary-button newsletter-input" (click)="appOeffnen()">App starten</button>
            </div>
        </div>
    }
    <!--============================================-->
    <!-- END Registration Successful -->
    <!--============================================-->

    <!--============================================-->
    <!-- Image for mobile -->
    <!--============================================-->
    @if (isMobile()) {
        <div id="image-badge-mobile">&nbsp;</div>
    }
    <!--============================================-->
    <!-- END Image for mobile -->
    <!--============================================-->
</div>

<!--============================================-->
<!-- IE / Edge warning -->
<!--============================================-->
<div
    id="old-browser-warning"
    style="display: none; background: gold; width: 100%; height: 100vh; align-items: center; justify-content: center">
    <div style="text-align: center">
        <h2 style="color: #5c5c5c">Internet Explorer wird nicht unterstützt</h2>
        <p>
            Bitte installiere einen modernen Browser wie z. B. die aktuellste Version von
            <a href="https://www.google.com/chrome/" target="_blank" rel="noopener">Google Chrome</a>
            oder
            <a href="https://www.mozilla.org/de/firefox/new/" target="_blank" rel="noopener">Mozilla Firefox</a>
            .
        </p>
    </div>
</div>
<script>
    // If it's the Internet Explorer, display a deprecation warning.
    if (document.documentMode) {
        document.getElementById('old-browser-warning').style.display = 'flex';
    }
</script>
<!--============================================-->
<!-- END IE / Edge warning -->
<!--============================================-->
