import { Component, inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { GoogleStructuredDataComponent } from '../../../shared/components/google-structured-data.component';
import { SUPPORT_AND_PRODUCT_MANAGER_GOOGLE_STRUCTURED_DATA } from './product-and-support-manager.google-structured-data';

@Component({
    selector: 'ax-product-and-support-manager',
    standalone: true,
    imports: [GoogleStructuredDataComponent, GoogleStructuredDataComponent],
    templateUrl: './support-and-product-manager.component.html',
    styleUrl: '../job-details.scss',
})
export class TechnicalItSupport {
    protected readonly titleService = inject(Title);
    protected readonly metaService = inject(Meta);

    protected googleStructuredData = SUPPORT_AND_PRODUCT_MANAGER_GOOGLE_STRUCTURED_DATA;

    constructor() {
        this.titleService.setTitle('Customer Support & Produktmanager | Jobs');
        this.metaService.addTag({
            name: 'description',
            content:
                'Bist du smart und hast gerne Kundenkontakt? Starte deine Karriere bei autoiXpert und berate Kunden bei der führenden Software für Kfz-Gutachter.',
        });
    }
}
