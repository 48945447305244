import { Component, inject, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { WebinarHeroComponent } from '../../components/webinar-hero/webinar-hero.component';
import {
    WebinarTargetGroupComponent,
    WebinarTargetGroupLevel,
} from '../../components/webinar-target-group/webinar-target-group.component';
import {
    WebinarInstructor,
    WebinarInstructorSectionComponent,
} from '../../components/webinar-instructor-section/webinar-instructor-section.component';
import { WEBINAR_INSTRUCTOR_RALPH_BURKARD } from '../../webinar-instructors';
import { WebinarAgendaComponent, WebinarContent } from '../../components/webinar-agenda/webinar-agenda.component';
import { getParameterByName } from '../../../../shared/lib/get-parameters-by-name';
import { FormsModule } from '@angular/forms';
import { GetWebinarTimePipe } from '../../pipes/get-webinar-time.pipe';
import { GetWebinarDateLongFormPipe } from '../../pipes/get-webinar-date-long-form.pipe';
import { GetWebinarDateLongFormWithWeekdayPipe } from '../../pipes/get-webinar-date-long-form-with-weekday.pipe';
import { WebinarSignupSectionComponent } from '../../components/webinar-signup-section/webinar-signup-section.component';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'ax-honorarkuerzungen-burkard',
    standalone: true,
    imports: [
        GetWebinarTimePipe,
        GetWebinarDateLongFormPipe,
        GetWebinarDateLongFormWithWeekdayPipe,
        FormsModule,
        WebinarHeroComponent,
        WebinarAgendaComponent,
        WebinarTargetGroupComponent,
        WebinarHeroComponent,
        WebinarAgendaComponent,
        WebinarTargetGroupComponent,
        WebinarInstructorSectionComponent,
        WebinarSignupSectionComponent,
    ],
    templateUrl: './honorarkuerzungen-burkard.component.html',
    styleUrl: '../fachwebinare.scss',
})
export class HonorarkuerzungenBurkardComponent {
    protected readonly titleService = inject(Title);
    protected readonly metaService = inject(Meta);
    protected readonly httpClient = inject(HttpClient);
    protected readonly platformId = inject(PLATFORM_ID);

    protected webinarDate: string | null = null;
    protected webinarTitle = 'Kürzungen & Gegenmittel';
    protected webinarTitleShort = 'Kürzungen & Gegenmittel';
    protected targetGroupLevel: WebinarTargetGroupLevel = {
        beginner: false,
        intermediate: true,
        professional: true,
    };
    protected instructors: Array<WebinarInstructor> = [WEBINAR_INSTRUCTOR_RALPH_BURKARD];
    protected price: number = 149;
    protected duration = 2;
    protected maximumNumberOfParticipants = 75;
    protected eventOverbooked = false;
    protected webinarContents: Array<WebinarContent> = [
        {
            title: 'Grundlagen',
            description: 'Vertragsrecht & Schadenersatz',
        },
        {
            title: 'Sachverständigenrisiko',
            description: 'Jüngste BGH-Rechtsprechung',
        },
        {
            title: 'Honorarvereinbarung als Schutz',
            description: 'plus: Wirksamkeit in Verträgen',
        },
        {
            title: 'Durchsetzung des vollen Honorars',
            description: 'Strategien gegen Kürzungen der Versicherung',
        },
        {
            title: 'Regress des Versicherers',
            description: 'Erfolgsaussichten für Versicherer',
        },
        {
            title: 'Aktuelle Urteile',
            description: 'Zeithonoraransatz, Restwert, Stellungnahmekosten & mehr',
        },
    ];

    constructor() {
        this.titleService.setTitle(`${this.webinarTitleShort} | Fach-Webinar`);
        this.metaService.addTag({
            name: 'description',
            content: `Fachwebinar ${this.webinarTitle} Wissen für Kfz-Sachverständige - rund um autoiXpert`,
        });
    }

    ngOnInit() {
        if (isPlatformBrowser(this.platformId)) {
            this.webinarDate = atob(getParameterByName('webinarDate') ?? '');
        }
    }
}
