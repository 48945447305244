import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';

export interface WebinarContent {
    title: string;
    description: string;
    /**
     * If you provide a link, the title will be linked.
     */
    link?: string;
}

@Component({
    selector: 'ax-webinar-agenda',
    standalone: true,
    templateUrl: './webinar-agenda.component.html',
    styleUrl: './webinar-agenda.component.scss',
    imports: [NgIf],
})
export class WebinarAgendaComponent {
    @Input() webinarTitle: string = '';
    @Input() webinarContents: Array<WebinarContent> = [];
    @Input() maximumNumberOfParticipants: number | null = 0;
    @Input() isOnlineExchange: boolean = false;
}
