<ax-webinar-hero
    [webinarDate]="webinarDate"
    [webinarTitleShort]="webinarTitleShort"
    [price]="price"
    [duration]="duration"></ax-webinar-hero>

<ax-webinar-agenda
    [maximumNumberOfParticipants]="maximumNumberOfParticipants"
    [webinarContents]="webinarContents"
    [webinarTitle]="webinarTitle"
    [isOnlineExchange]="true">
    <p>
        KFZ-Sachverständige stellen sich in ihrer täglichen Arbeit regelmäßig neuen Herausforderungen. Ein
        tiefgreifender fachlicher Erfahrungsaustausch zwischen einzelnen Sachverständigen erfolgt erfahrungsgemäß
        äußerst selten. Im Rahmen der Veranstaltung haben die Teilnehmer die Möglichkeit, sich zu allen Themen rund um
        das Sachverständigenwesen auszutauschen. Es handelt sich ausdrücklich nicht um eine Veranstaltung mit
        vorgegebenem Themenspektrum, sondern vielmehr um eine offene Diskussionsrunde, um aus den Erfahrungen aller
        Teilnehmer zu lernen.
    </p>
    <!-- prettier-ignore -->
    <p>
        Um die Themen moderiert gestalten zu können, senden Sie uns bitte Ihre
        Themen oder Diskussionspunkte vorab an
        <a
            href="mailto:events@autoixpert.de?subject=Mein%20Thema%20für%20den%20Erfahrungsaustausch">
            events&#64;autoixpert.de
        </a>.
    </p>
</ax-webinar-agenda>

<ax-webinar-target-group [targetGroupLevel]="targetGroupLevel">
    Für erfahrene Kfz-Sachverständige, die sich zu fachlichen und organisatorischen Themen austauschen möchten.
</ax-webinar-target-group>

<ax-webinar-instructor-section [instructors]="instructors" [isOnlineExchange]="true"></ax-webinar-instructor-section>

<ax-webinar-signup-section
    [webinarDate]="webinarDate"
    [duration]="duration"
    [price]="price"
    [webinarTitle]="webinarTitle"
    [webinarContents]="webinarContents"
    [instructor]="instructors[0].name"
    [eventOverbooked]="eventOverbooked"
    [isOnlineExchange]="true"></ax-webinar-signup-section>
