export const SUPPORT_AND_PRODUCT_MANAGER_GOOGLE_STRUCTURED_DATA = {
    jobPosting: {
        '@context': 'http://schema.org',
        '@type': 'JobPosting',
        title: 'Technischer IT-Support',
        description:
            'Bist du smart und hast gerne Kundenkontakt? Starte deine Karriere bei autoiXpert, lerne von erfahrenen & ambitionierten Team-Kollegen und arbeite an der führenden Software für Kfz-Gutachter.',
        datePosted: '2024-07-03',
        validThrough: '2024-12-01',
        experienceRequirements: {
            '@type': 'OccupationalExperienceRequirements',
            monthsOfExperience: '0',
        },
        applicantLocationRequirements: {
            '@type': 'Country',
            name: 'DE',
        },
        // TELECOMMUTE means that this is a home office job.
        jobLocationType: 'TELECOMMUTE',
        employmentType: ['FULL_TIME', 'PART_TIME'],
        hiringOrganization: {
            '@type': 'Organization',
            name: 'autoiXpert GmbH & Co. KG',
            sameAs: 'https://www.autoixpert.de',
            logo: 'https://www.autoixpert.de/images/logo-autoixpert.svg',
        },
        baseSalary: {
            '@type': 'MonetaryAmount',
            currency: 'EUR',
            value: {
                '@type': 'QuantitativeValue',
                minValue: 55_000.0,
                maxValue: 65_000.0,
                unitText: 'YEAR',
            },
        },
        directApply: true,
    },
};
