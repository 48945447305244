<section id="job-list-section">
    <div class="container">
        <div class="row mb-md-5">
            <div class="col text-center">
                <h3 class="additional-headline">Ready to rumble!</h3>
                <h2>Werde Teil unseres Teams</h2>
                <!-- Don't let prettier format the following inline elements (to prevent whitespace between anchor and full stop) -->
                <!-- prettier-ignore -->
                <p>
                    Stöbere gerne in unseren Jobs. Bei Fragen
                    <a href="tel:+4982229188990">ruf gerne an</a>
                    oder
                    <a href="mailto:info@autoixpert.de">schick uns eine Mail</a>.
                </p>
            </div>
        </div>

        <!--============================================-->
        <!-- Job Posting - Senior Developer -->
        <!--============================================-->
        <div class="row justify-content-center">
            <div class="col">
                <a class="job-posting-container" href="/jobs/senior-developer">
                    <h3>Senior Angular Developer</h3>
                    <span class="details-experience details-tag">Sehr hohes Skill-Level</span>
                    <span
                        class="details-salary details-tag"
                        title="Je besser deine Qualifikation als Developer zu uns passt, desto mehr Gehalt kannst du verdienen.">
                        <i class="material-icons-outlined">euro_symbol</i>
                        75.000 - 95.000 €
                    </span>
                    <!--                    <div class="all-positions-occupied">-->
                    <!--                        Alle Stellen besetzt.-->
                    <!--                        <div class="unsolicited-application-possible">Initiativbewerbung möglich.</div>-->
                    <!--                    </div>-->
                    <span class="details-link">Details</span>
                </a>
            </div>
        </div>
        <!--============================================-->
        <!-- END Job Posting - Senior Developer -->
        <!--============================================-->

        <!--============================================-->
        <!-- Job Posting - Developer -->
        <!--============================================-->
        <div class="row justify-content-center">
            <div class="col">
                <a class="job-posting-container" href="/jobs/developer">
                    <h3>Angular Developer</h3>
                    <span class="details-experience details-tag">Hohes Skill-Level</span>
                    <span
                        class="details-salary details-tag"
                        title="Je besser deine Qualifikation als Developer zu uns passt, desto mehr Gehalt kannst du verdienen.">
                        <i class="material-icons-outlined">euro_symbol</i>
                        65.000 - 75.000 €
                    </span>
                    <div class="all-positions-occupied-note">
                        Alle Stellen besetzt.
                        <div class="unsolicited-application-possible">Initiativbewerbung möglich.</div>
                    </div>
                    <span class="details-link">Details</span>
                </a>
            </div>
        </div>
        <!--============================================-->
        <!-- END Job Posting - Developer -->
        <!--============================================-->
      <!--============================================-->
      <!-- Job Posting - Junior Developer -->
      <!--============================================-->
      <div class="row justify-content-center">
        <div class="col">
          <a class="job-posting-container" href="/jobs/junior-developer">
            <h3>Junior Developer</h3>
            <span class="details-experience details-tag">Geringes Skill-Level, aber viel Motivation 😉</span>
            <span
              class="details-salary details-tag"
              title="Je besser deine Qualifikation als Developer zu uns passt, desto mehr Gehalt kannst du verdienen.">
                        <i class="material-icons-outlined">euro_symbol</i>
                        40.000 - 50.000 €
                    </span>
            <div class="all-positions-occupied-note">
              Alle Stellen besetzt.
              <div class="unsolicited-application-possible">Initiativbewerbung möglich.</div>
            </div>
            <span class="details-link">Details</span>
          </a>
        </div>
      </div>
      <!--============================================-->
      <!-- END Job Posting - Junior Developer -->
      <!--============================================-->
      <!--============================================-->
      <!-- Job Posting - Technical Support -->
      <!--============================================-->
      <div class="row justify-content-center">
        <div class="col">
          <a class="job-posting-container" href="/Jobs/Technischer-IT-Support">
            <h3>Technischer IT-Support</h3>
            <span class="details-experience details-tag">Technische Skills & Freundlichkeit</span>
            <span
              class="details-salary details-tag"
              title="Je besser deine Qualifikation für Produktmanagement und Support zu uns passt, desto mehr Gehalt kannst du verdienen.">
                        <i class="material-icons-outlined">euro_symbol</i>
                        55.000 - 65.000 €
                    </span>
            <div class="all-positions-occupied-note">
              Alle Stellen besetzt.
<!--              <div class="unsolicited-application-possible">Initiativbewerbung möglich.</div>-->
            </div>
            <span class="details-link">Details</span>
          </a>
        </div>
      </div>
      <!--============================================-->
      <!-- END Job Posting - Technical Support -->
      <!--============================================-->

        <!--============================================-->
        <!-- Job Posting - Customer Support Agent -->
        <!--============================================-->
        <div class="row justify-content-center">
            <div class="col">
                <a class="job-posting-container" href="/jobs/customer-support-agent">
                    <h3>Customer Support Agent</h3>
                    <span class="details-experience details-tag">Lernbereitschaft & Freundlichkeit</span>
                    <span
                        class="details-salary details-tag"
                        title="Je besser deine Qualifikation als Customer Support Agent zu uns passt, desto mehr Gehalt kannst du verdienen.">
                        <i class="material-icons-outlined">euro_symbol</i>
                        45.000 - 50.000 €
                    </span>
                    <div class="all-positions-occupied-note">
                        Alle Stellen besetzt.
<!--                        <div class="unsolicited-application-possible">Initiativbewerbung möglich.</div>-->
                    </div>
                    <span class="details-link">Details</span>
                </a>
            </div>
        </div>
        <!--============================================-->
        <!-- END Job Posting - Customer Support Agent -->
        <!--============================================-->
      <!--============================================-->
      <!-- Job Posting - Organizational Talent -->
      <!--============================================-->
      <div class="row justify-content-center">
        <div class="col">
          <a class="job-posting-container" href="/jobs/organisationstalent">
            <h3>
              Organisationstalent
              <!--(4-8 h Woche)-->
            </h3>
            <span class="details-experience details-tag">Mehrjährige Erfahrung</span>
            <span
              class="details-salary details-tag"
              title="Bei uns verdienst du überdurchschnittlich mit 25 € pro Stunde.">
                        <i class="material-icons-outlined">euro_symbol</i>
                        520 €-Basis (20 € / Stunde)
                    </span>
            <div class="all-positions-occupied-note">
              Alle Stellen besetzt.
              <!--                        <div class="unsolicited-application-possible">Initiativbewerbung möglich.</div>-->
            </div>
            <span class="details-link">Details</span>
          </a>
        </div>
      </div>
      <!--============================================-->
      <!-- END Job Posting - Organizational Talent -->
      <!--============================================-->
    </div>
</section>
