<ax-webinar-hero
    [webinarDate]="webinarDate"
    [webinarTitleShort]="webinarTitleShort"
    [price]="price"
    [duration]="duration"></ax-webinar-hero>

<ax-webinar-agenda
    [maximumNumberOfParticipants]="maximumNumberOfParticipants"
    [webinarContents]="webinarContents"
    [webinarTitle]="webinarTitle">
    <p>
        Entdecke in diesem informativen Webinar, wie die autoiXpert-Buchhaltungstools deine täglichen Finanzprozesse
        revolutionieren können. Lerne, wie du Zeit sparen, Fehler minimieren und deine Buchhaltung auf ein neues Niveau
        heben kannst. Dieses Webinar ist ideal für Unternehmer, Buchhalter und Finanzverantwortliche. Nutze diese
        Gelegenheit, um deine Fragen zu stellen und von unseren Experten zu lernen.
    </p>
</ax-webinar-agenda>

<ax-webinar-target-group [targetGroupLevel]="targetGroupLevel">
    Für Kfz-Sachverständige und ihre Mitarbeiter, die mit autoiXpert ihre Buchhaltung managen möchten.
</ax-webinar-target-group>

<ax-webinar-instructor-section [instructors]="instructors"></ax-webinar-instructor-section>

<ax-webinar-signup-section
    [webinarDate]="webinarDate"
    [duration]="duration"
    [price]="price"
    [webinarTitle]="webinarTitle"
    [webinarContents]="webinarContents"
    [instructor]="instructors[0].name"
    [eventOverbooked]="eventOverbooked"></ax-webinar-signup-section>
